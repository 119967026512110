.fs(@font-size) {
    font-size: @font-size / 37.5rem;
}

@info: #cdac7a;
































.layout                 {
    &-page              {min-height: 100%;
        &:before        {content: '';display: block;position: fixed;top: 0;left: 0;height: 100%;width: 100%;z-index: -1;background: #F5F7FA;}
        &.white:before  {background: #FFF;}
        &.header        {padding-top: 46px;}
        &.tabbar        {padding-bottom: 1.44rem;}
        &-header        {position: fixed;top: 0;left: 0;width: 100%;height: 46px;border-bottom: 1px solid #eaeaea;z-index: 100;background: #FFF;}
        &-content       {min-height: 100%;}
    }
}
