.layout-tabbar {
  height: 1.466667rem;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  border-top: 1px solid #eaeaea;
  z-index: 100;
  background: #FFF;
}
.layout-tabbar-content {
  max-width: 10rem;
  width: 100%;
  margin: 0 auto;
  display: flex;
}
.layout-tabbar-content a {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: .18666rem;
}
.layout-tabbar-content a i {
  height: .7rem;
  font-size: 0.69333333rem;
  color: #666;
}
.layout-tabbar-content a span {
  font-size: 0.26666667rem;
  display: block;
  margin-top: .02rem;
  color: #666;
}
.layout-tabbar-content a.active span,
.layout-tabbar-content a.active i {
  color: #5ec2b5;
}
