.layout-page {
  min-height: 100%;
}
.layout-page:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
  background: #F5F7FA;
}
.layout-page.white:before {
  background: #FFF;
}
.layout-page.header {
  padding-top: 46px;
}
.layout-page.tabbar {
  padding-bottom: 1.44rem;
}
.layout-page-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 46px;
  border-bottom: 1px solid #eaeaea;
  z-index: 100;
  background: #FFF;
}
.layout-page-content {
  min-height: 100%;
}
