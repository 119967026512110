.fs(@font-size) {
    font-size: @font-size / 37.5rem;
}

@info: #cdac7a;




















.layout                 {
    &-page              {
        &:before        {content: '';display: block;position: fixed;top: 0;left: 0;height: 100%;width: 100%;z-index: -1;background: #F5F7FA;}
        &.white:before  {background: #FFF;}
    }
}
