.fs(@font-size) {
    font-size: @font-size / 37.5rem;
}

@info: #cdac7a;
@charset "utf-8";
div,
span,
p,
dl,
dt,
a,
button       { -webkit-tap-highlight-color: rgba(255, 255, 255, 0); -moz-user-focus: none; -moz-user-select: none; box-sizing: border-box; -webkit-font-smoothing: antialiased;-moz-osx-font-smoothing: grayscale;}
button,
input, 
optgroup,
select,
textarea     { box-sizing: border-box; color: inherit; font: inherit; margin: 0; outline: none; padding: 0; margin: 0; }
.flex        { display: -webkit-flex; display: flex;}
.rel         { position: relative; }
.sta         { position: static; }
.abs         { position: absolute; }
.fix         { position: fixed; }
.left        { float: left; }
.right       { float: right; }
.tc          { text-align: center; }
.tn          { white-space: nowrap; word-wrap: normal; /* for IE */ }
.wfull       { width: 100%!important; }
.show        { display: block; }
.hide        { display: none; }
.font        { font-family: PingFangSC-Light,helvetica,'Heiti SC'; }
.db          { display: block; }
.dib         { display: inline-block; }
.bsbb        { box-sizing: border-box; }

h1,
h2,
h3,
h4,
h5,
h6                   { font-weight: normal; margin: 0; padding: 0; }
ul,ol                { list-style: none; -webkit-padding-start: 0; padding: 0px; margin: 0px; }
i,em                 { font-style: normal; }
p                    { margin: 0px; padding: 0px; }
body                 { position: relative; width: 100%;max-width: 10rem;margin: 0 auto!important;background: #F5F7FA!important;overflow-x: hidden; -webkit-overflow-scrolling: touch; }
html,
body                 { font-family: "AliFont", "Microsoft Yahei", "Helvetica Neue", Helvetica, Arial, sans-serif, PingFangSC-Light,helvetica,'Heiti SC';min-height: 100%;}
input                { font-size:14px;-webkit-appearance: none;}
input[type="submit"],
input[type="button"],
input[type="text"]   { -webkit-appearance: none !important; }
img                  { display: block; width: 100%; }
html,
body,
a                    { text-decoration: none; color: #333; font-size:14px; }

.noSelect            { -webkit-user-select: none; -moz-user-select: none; -ms-user-select: none; -o-user-select: none; user-select: none; }
.clear:before,
.clear:after         { content: ""; display: table; }
.clear:after         { clear: both; }
.clear               { zoom: 1; }
.smoothing           { -webkit-font-smoothing: antialiased;-moz-osx-font-smoothing: grayscale; }

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {color: #aab2bd;}
input:-webkit-autofill,select:-webkit-autofill {-webkit-box-shadow: 0 0 0px 1000px white  inset !important;} 

#app                 {min-height: 100%;
    &:after          {content: '';display: block;box-shadow: 0 0 .08rem .08rem rgba(0,0,0,.2);position: fixed;top: 0;left: 50%;height: 100%;width: 10rem;z-index: -1;transform: translate(-50%);}
}

.fixed-top           {position: fixed;top: 0;left: 0;width: 100%;}

.abs-img             { position: absolute;width: 100%;height: 100%;top: 0;left: 0;
    &-center         { position: absolute;top: 50%;left: 50%;transform: translate3d(-50%, -50%, 0);width: 100%;height: auto;}
}
