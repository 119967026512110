.fs(@font-size) {
    font-size: @font-size / 37.5rem;
}

@info: #cdac7a;




































.layout-tabbar              {height: 1.466667rem;position: fixed;bottom: 0;left: 0;width: 100%;border-top: 1px solid #eaeaea;z-index: 100;background: #FFF;
    &-content               {max-width: 10rem;width: 100%;margin: 0 auto;display: flex;
        a                   {display: flex;flex: 1;flex-direction: column;justify-content: center;align-items: center;padding-top: .18666rem;
            i               {height: .7rem;.fs(26);color: #666;}
            span            {.fs(10);display: block;margin-top: .02rem;color: #666;}
            &.active        {
                span,i      {color: #5ec2b5;}
            }
        }
    }
}
